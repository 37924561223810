import * as yup from "yup";

export interface BrandVoiceObject {
  name: string;
  voice: string;
  alias: string;
  gender: string;
  ageGroup: string[];
  incomeBracket: string;
  primaryUsage: string;
  toneAndStyle: string[];
  descriptor1: string;
  descriptor2: string;
  descriptor3: string;
  sellingPoints: string[];
  competitorAwareness: string[];
}

export const inputName = {
  name: "name",
  voice: "voice",
  alias: "alias",
  gender: "gender",
  ageGroup: "ageGroup",
  incomeBracket: "incomeBracket",
  primaryUsage: "primaryUsage",
  toneAndStyle: "toneAndStyle",
  descriptor1: "descriptor1",
  descriptor2: "descriptor2",
  descriptor3: "descriptor3",
  sellingPoints: "sellingPoints",
  competitorAwareness: "competitorAwareness",
};

export const initialValue: BrandVoiceObject = {
  name: "",
  alias: "",
  voice: "",
  gender: "men",
  ageGroup: [],
  incomeBracket: "allIncome",
  primaryUsage: "For Home User",
  toneAndStyle: [],
  descriptor1: "",
  descriptor2: "",
  descriptor3: "",
  sellingPoints: [""],
  competitorAwareness: [""],
};

export const validationSchemaForInternalUser = yup.object().shape({
  name: yup.string().trim().required("Full name is required"),
  email: yup
    .string()
    .trim()
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Please add valid email address")
    .required("Work email is required"),
  userRole: yup.string().trim().required("User's Role is required"),
  password: yup.string().trim().min(6, "Password must be at least 6 characters long").required("Password is required"),
  confirmPassword: yup
    .string()
    .trim()
    .required("Please re-enter your new password")
    .oneOf([yup.ref("password")], "Passwords must match"),
});
export const validationSchemaForBrandAlias = yup.object().shape({
  name: yup.string().trim().required("Brand name is required"),
  voice: yup
    .string()
    .trim()
    .min(1, "Sample content atleast contain the word")
    .max(500, "Sample content must not exceed 500 characters"),
});

export const genderOptions = [
  { label: "Men", value: "men" },
  { label: "Women", value: "women" },
  { label: "Both", value: "both" },
];

export const incomeBracketOptions = [
  { label: "All income levels", value: "allIncome" },
  { label: "Above average income", value: "aboveAverage" },
  { label: "High Income", value: "highIncome" },
];

export const primaryUsageOptions = [
  { label: "For home user", value: "For Home User" },
  { label: "For use outside the house", value: "For use outside the house" },
  { label: "Both", value: "Both" },
];

export const toneAndStyleOptions = [
  { label: "Luxurious", value: "Luxurious" },
  { label: "Practical", value: "Practical" },
  { label: "Professional", value: "Professional" },
  { label: "Funny", value: "Funny" },
  { label: "Educational (Talks like a Doctor)", value: "Educational (Talks like a Doctor)" },
  { label: "Persuasive (Talks like a Salesperson)", value: "Persuasive (Talks like a Salesperson)" },
  { label: "Eco-friendly/Sustainable", value: "Eco-friendly/Sustainable" },
  { label: "Innovative/High-tech", value: "Innovative/High-tech" },
  { label: "Traditional/Classic", value: "Traditional/Classic" },
];
export const ageGroupOptions = [
  {
    value: "18-30 years",
    label: "18-30 years",
  },
  {
    value: "31-43 years",
    label: "31-43 years",
  },
  {
    value: "44-56 years",
    label: "44-56 years",
  },
  {
    value: "57-65 years",
    label: "57-65 years",
  },
  {
    value: "65+ years",
    label: "65+ years",
  },
];
